import React from "react";
import "./Project.css";
import { Link } from "react-router-dom";

const ProjectList = ({ branch, projects }) => {
  return (
    <div className="project-list" id="Project-List-BarChart">
      <h3>مشاريع اليوم فرع {branch}</h3>
      <div className="project-container">
        {projects.map((project, index) => (
          <Link
            to={`/project/${
              project.type === "المشاريع الخاصة"
                ? "privateproject"
                : project.type === "العمليات"
                ? "operation"
                : project.type === "الصيانة"
                ? "maintenance"
                : "newproject"
            }/${project.id}`}
            className="project-card"
            key={index}
          >
            {project.isNetworkImplemented ? (
              <p>تم تنفيذ الشبكة</p>
            ) : (
              <p>لم يتم تنفيذ الشبكة</p>
            )}
            <p className="numberOrder">رقم الطلب: {project.orderNumber}</p>
            <p>{project.userName}</p>
            <div className="project-image">
              <img src={project.userImage} alt={project.userName} />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProjectList;
