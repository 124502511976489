import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import UploadIcon from "../../Image/UploadIcon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import { Url } from "../../function/FunctionApi";
import Swal from "sweetalert2";
import { useSearchParams } from "react-router-dom";

const createUrl = `${Url}NewProject/create-newProject`;
const updateUrl = `${Url}NewProject/update`;

const RadioGroup = ({ label, options, name, selectedValue, onChange }) => (
  <div className="violations">
    <h4>{label}</h4>
    <div className="radio-group">
      {options.map((option) => (
        <label key={option.value}>
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={
              selectedValue === (option.value === "exists" ? true : false)
            }
            onChange={onChange}
          />
          <span className="radio-circle" />
          {option.label}
        </label>
      ))}
    </div>
  </div>
);

const Form = ({ userData, apiData }) => {
  const [searchParams] = useSearchParams();
  const office = searchParams.get("office");
  console.log("offfice+++++++++++++");
  console.log(office);
  console.log("offfice+++++++++++++");
  const [formData, setFormData] = useState({
    ProjectType: "",
    FaultNumber: "",

    FaultType: "",
    District: "",
    Office: office || "",
    Resources: "",
    OrderType: "",
    ReceiveDateTime: "",
    Situation: "",
    Contractor: "",
    Consultant: userData.displayName,
    Note: "",
    SafetyViolationsExist: "",
    EstimatedValue: "",
    ActualValue: "",
    ExtractNumber: "",
  });

  const [fileData, setFileData] = useState({
    ModelPhotos: [],
    SitePhotos: [],
    SafetyWastePhotos: [],
  });

  const fileInputRefs = {
    ModelPhotos: useRef(null),
    SitePhotos: useRef(null),
    SafetyWastePhotos: useRef(null),
  };

  const [errorMessage, setErrorMessage] = useState("");
  const token = userData?.token;
  const [showModal, setShowModal] = useState({ success: false, error: false });
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (apiData) {
      setFormData({
        ProjectType: apiData.projectType || "",
        FaultType: apiData.faultType || "",
        FaultNumber: apiData.faultNumber || "",
        Contractor: apiData.contractor || "",
        District: apiData.district || "",
        Consultant: apiData.consultant || "",
        Resources: apiData.resources || "",
        Note: apiData.note || "",
        OrderType: apiData.orderType || "",
        ReceiveDateTime: apiData.receiveDateTime || "",
        Situation: apiData.situation || "",
        SafetyViolationsExist: apiData.safetyViolationsExist,
        EstimatedValue: apiData.estimatedValue,
        ActualValue: apiData.actualValue,
        ExtractNumber: apiData.extractNumber,
      });
      setFileData({
        ModelPhotos: apiData.modelPhotos || [],
        SitePhotos: apiData.sitePhotos || [],
        SafetyWastePhotos: apiData.safetyWastePhotos || [],
      });
    }
  }, [apiData]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prev) => ({
      ...prev,
      [name]:
        name === "SafetyViolationsExist"
          ? value === "exists"
            ? true
            : false
          : value,
    }));
  };

  const openFileSelector = (fileType) => {
    fileInputRefs[fileType].current.click();
  };

  const handleFileChange = (event, fileType) => {
    const uploadedFiles = Array.from(event.target.files);
    const newFiles = uploadedFiles.filter((file) => {
      if (file.type === "application/pdf") {
        return true;
      }
      return file.size <= 1 * 1024 * 1024 * 1024;
    });
    const rejectedFiles = uploadedFiles.filter((file) => {
      return (
        file.type !== "application/pdf" && file.size > 1 * 1024 * 1024 * 1024
      );
    });

    if (rejectedFiles.length) {
      setErrorMessage("بعض الملفات كانت كبيرة جدًا ولا يمكن رفعها.");
    } else {
      setErrorMessage("");
    }

    setFileData((prev) => ({
      ...prev,
      [fileType]: [...prev[fileType], ...newFiles],
    }));
  };

  const handleFileDelete = (fileType, fileName) => {
    setFileData((prev) => ({
      ...prev,
      [fileType]: prev[fileType].filter((file) => file.name !== fileName),
    }));
  };

  const handleSubmit = async (isArchive) => {
    if (
      formData.Office === "" ||
      formData.ProjectType === "" ||
      formData.Resources === "" ||
      formData.OrderType === "" ||
      formData.Situation === "" ||
      formData.ReceiveDateTime === "" ||
      formData.ExtractNumber === "" ||
      formData.FaultType === "" ||
      formData.ActualValue === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "يرجى ملء جميع الحقول!",
      });
      return;
    }
    const submissionData = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      submissionData.append(key, value);
    });

    submissionData.append("isArchive", isArchive);

    Object.entries(fileData).forEach(([key, files]) => {
      files.forEach((file) => {
        submissionData.append(key, file);
      });
    });

    setLoading(true);
    setUploadProgress(0);

    try {
      const url = apiData ? updateUrl : createUrl;
      const method = apiData ? "PUT" : "POST";

      await axios({
        method,
        url,
        data: submissionData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
      if (apiData) {
        setSuccessMessage("تم تحديث الطلب بنجاح.");
      } else {
        setSuccessMessage("تم إرسال الطلب بنجاح.");
      }
      setShowModal({ success: true, error: false });
    } catch (error) {
      let errorMessage =
        error?.response?.data?.data ||
        "فشل إرسال النموذج. يرجى المحاولة مرة أخرى.";
      setErrorMessage(errorMessage);

      setShowModal({ success: false, error: true });
    } finally {
      setLoading(false);
      setFormData({
        ProjectType: "",
        FaultNumber: "",

        FaultType: "",
        District: "",
        Office: office || "",
        Resources: "",
        OrderType: "",
        ReceiveDateTime: "",
        Situation: "",
        Contractor: "",
        Consultant: userData.displayName,
        Note: "",
        SafetyViolationsExist: "",
        EstimatedValue: "",
        ActualValue: "",
        ExtractNumber: "",
      });
    }
  };
  const renderUploadSection = (
    label,
    fileType,
    buttonLabel,
    multiple = true
  ) => (
    <div className="upload-section">
      <h4>{label}</h4>
      <div className="upload-box">
        <span className="upload-icon">
          <img src={UploadIcon} alt="Upload" />
        </span>
        <p>{buttonLabel}</p>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, fileType)}
          multiple={multiple}
          ref={fileInputRefs[fileType]}
          style={{ display: "none" }}
          accept=".jpg,.jpeg,.png,.pdf"
        />
        <div className="button-group">
          <button
            type="button"
            className="upload-button"
            onClick={() => openFileSelector(fileType)}
          >
            رفع ملف
          </button>
        </div>
      </div>
      <div className="file-list">
        {!apiData &&
          fileData[fileType].map((file, index) => (
            <div key={index} className="file-item">
              {file instanceof File ? (
                file.type === "application/pdf" ? (
                  <a
                    href={URL.createObjectURL(file)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="file-link"
                  >
                    {file.name}
                  </a>
                ) : (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Uploaded file"
                    className="uploaded-image"
                  />
                )
              ) : null}
              <button
                className="delete-button"
                onClick={() => handleFileDelete(fileType, file.name)}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          ))}
        {apiData &&
          fileData[fileType]?.map((item, index) => (
            <div key={index} className="file-item">
              {item.url &&
                (item.url.endsWith(".pdf") ? (
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="file-link"
                  >
                    {item.url.split("/").pop() || "ملف"}
                  </a>
                ) : (
                  <img
                    src={item.url}
                    className="uploaded-image"
                    alt="uploaded-image"
                  />
                ))}
              {item instanceof File ? (
                item.type === "application/pdf" ? (
                  <a
                    href={URL.createObjectURL(item)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="file-link"
                  >
                    {item.name}
                  </a>
                ) : (
                  <img
                    src={URL.createObjectURL(item)}
                    alt="Uploaded file"
                    className="uploaded-image"
                  />
                )
              ) : null}
              <button
                className="delete-button"
                onClick={() => handleApiFileDelete(fileType, item.id)}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          ))}
      </div>
    </div>
  );

  const handleApiFileDelete = async (fileType, fileId) => {
    const endpointMap = {
      ModelPhotos: "NewProject/model-photo",
      SitePhotos: "NewProject/site-photo",
      SafetyWastePhotos: "NewProject/safety-photo",
    };

    const endpoint = `${Url}${endpointMap[fileType]}?photoId=${fileId}`;
    try {
      const response = await axios.delete(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setFileData((prev) => ({
          ...prev,
          [fileType]: prev[fileType].filter((item) => item.id !== fileId),
        }));
        Swal.fire({
          position: "center",
          icon: "success",
          title: "تم حذف الصوره بنجاح.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "فشل حذف الصورة",
        text: "يرجى المحاولة مرة أخرى.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return (
    <div className="form-container" dir="rtl">
      <div className="container">
        <div className="FormData">
          <div sx={{ textAlign: "center", marginBottom: "30px" }}>
            <h3>بيانات الطلب</h3>
            <p>Get a Quote Immediately Upon Form Submission</p>
          </div>

          {[
            ["نوع المشروع", "رقم امر العمل "],
            ["نوع العطل", "الحي"],
            ["الموارد", "المقاول"],
            ["القيمة المقدرة", "القيمة الفعلية"],
            ["الاستشاري", "رقم المستخلص"],
          ].map((pair, index) => (
            <div key={index} className="input-group">
              {pair.map((placeholder, idx) => (
                <div className="groub_fe">
                  <label key={idx}>{placeholder}</label>
                  <input
                    key={idx}
                    type="text"
                    name={
                      [
                        "ProjectType",
                        "FaultNumber",
                        "FaultType",
                        "District",
                        "Resources",
                        "Contractor",
                        "EstimatedValue",
                        "ActualValue",
                        "Consultant",
                        "ExtractNumber",
                      ][index * 2 + idx]
                    }
                    placeholder={placeholder}
                    value={
                      formData[
                        [
                          "ProjectType",
                          "FaultNumber",
                          "FaultType",
                          "District",
                          "Resources",
                          "Contractor",
                          "EstimatedValue",
                          "ActualValue",
                          "Consultant",
                          "ExtractNumber",
                        ][index * 2 + idx]
                      ]
                    }
                    onChange={handleChange}
                    required
                  />
                </div>
              ))}
            </div>
          ))}
          <div className="input-group">
            <div className="groub_fe">
              <label>نوع الطلب </label>
              <input
                key={"OrderType"}
                type="text"
                name={"OrderType"}
                placeholder={"نوع الطلب"}
                value={formData["OrderType"]}
                onChange={handleChange}
                required
              />
            </div>
            {/* <div className="groub_fe">
              <label> مده التنفيذ</label>
              <input
                datepicker
                key={"executeTime"}
                name={"executeTime"}
                value={formData["executeTime"]}
                type="text"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="مده التنفيذ"
              />
            </div> */}
          </div>
          <div className="input-group">
            <div className="groub_fe">
              <label> تاريح استلام امر الطلب</label>
              <input
                key={"ReceiveDateTime"}
                type="date"
                name={"ReceiveDateTime"}
                placeholder={"تاريخ استلام امر الطلب"}
                value={formData["ReceiveDateTime"]}
                onChange={handleChange}
                required
              />
            </div>
            <div className="groub_fe">
              <label>موقف التنفيذ</label>
              <select
                id="Situation"
                name="Situation"
                className="selectSituation"
                onChange={handleChange}
                value={formData.Situation}
              >
                <option value="pending">جاري</option>
                <option checked value="finish">
                  {" "}
                  منتهي
                </option>

                <option value="notFinished">لم يتم التنفيذ</option>
              </select>
            </div>
          </div>

          {renderUploadSection(
            "النموزج",
            "ModelPhotos",
            "تصوير مستندات مع الختم"
          )}
          {renderUploadSection("صور الموقع", "SitePhotos", "صور الموقع")}

          <RadioGroup
            label="مخالفات السلامة"
            options={[
              { label: "يوجد", value: "exists" },
              { label: "لا يوجد", value: "not-exists" },
            ]}
            name="SafetyViolationsExist"
            selectedValue={formData.SafetyViolationsExist}
            onChange={handleChange}
          />

          {formData.SafetyViolationsExist &&
            renderUploadSection(
              "صور مخالفات السلامة",
              "SafetyWastePhotos",
              "صور المخالفات"
            )}

          <div className="input-group">
            <textarea
              name="Note"
              placeholder="الملاحظات"
              value={formData.Note}
              onChange={handleChange}
            />
          </div>

          <div className="button-group">
            <button
              className={`submit-button px-4 py-2 rounded ${
                loading ? "bg-gray-400 cursor-not-allowed" : "bg-green-600"
              } text-white`}
              onClick={() => {
                if (formData.Situation === "finish") {
                  handleSubmit(false); // Continue process
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "خطأ",
                    text: " الطلب لم يتم تنفيذه",
                  });
                }
              }}
              disabled={loading}
            >
              {loading ? "جاري التحميل..." : "تم التنفيذ"}
            </button>

            <button
              type="button"
              className={`submit-button px-4 py-2 rounded ${
                loading ? "bg-gray-400 cursor-not-allowed" : "bg-green-600"
              } text-white`}
              style={{ background: "rgba(244, 67, 54, 1)", color: "white" }}
              onClick={() => {
                if (formData.Situation !== "finish") {
                  handleSubmit(true);
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "خطأ",
                    text: "الطلب بالفعلا تم تنفيذه",
                  });
                }
              }}
              disabled={loading}
            >
              {loading ? "جاري التحميل..." : "تحت التنفيذ"}
            </button>
          </div>
        </div>
      </div>
      {/* Progress Modal */}
      <Modal show={loading} onHide={() => setLoading(false)}>
        <Modal.Header>
          <Modal.Title>جارٍ رفع البيانات</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>جاري رفع البيانات، الرجاء الانتظار...</p>
          <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
        </Modal.Body>
      </Modal>
      {/* Modals for Success and Error */}
      <Modal
        show={showModal.success}
        onHide={() => setShowModal({ ...showModal, success: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>نجاح</Modal.Title>
        </Modal.Header>
        <Modal.Body>{successMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModal({ ...showModal, success: false })}
          >
            إغلاق
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModal.error}
        onHide={() => setShowModal({ ...showModal, error: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>خطأ</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModal({ ...showModal, error: false })}
          >
            إغلاق
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Form;
