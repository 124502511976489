import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { Url } from "../../function/FunctionApi"
import axios from 'axios';
import Swal from 'sweetalert2';

const Form = ({ ApiData, id, type }) => {
    const navigate = useNavigate();

    const {
        projectType,
        orderNumber,
        contractor,
        district,
        station,
        modelPhotos,
        sitePhotos,
        safetyWastePhotos,
        note,
        isExist,
    } = ApiData;

    const projectTypes = ["طوارئ", "احلال", "التعزيز", "الجهد المتوسط"];
    const projectTypeLabel = projectTypes[projectType] || "غير محدد";
    const [showModal, setShowModal] = useState(false);

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <title>${orderNumber} طلب رقم</title>
                    <style>
                        body { font-family: 'Amiri', sans-serif; direction: rtl; text-align: right; }
                        .container { margin: 20px; }
                        table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                        th, td { border: 1px solid #000; padding: 10px; text-align: right; }
                        th { background-color: #f2f2f2; }
                        .image-container { display: flex; justify-content: space-between; margin-bottom: 20px; }
                        .file-item { margin: 5px; }
                    </style>
                </head>
                <body>
                    <div class="container">
                        <h2>رقم الطلب: ${orderNumber}</h2>
                        <table>
                            <tr><th>المقاول</th><td>${contractor}</td></tr>
                            <tr><th>الحي</th><td>${district}</td></tr>
                            <tr><th>المحطة</th><td>${station}</td></tr>
                            <tr><th>نوع النموذج</th><td>${projectTypeLabel}</td></tr>
                            <tr><th>ملاحظات</th><td>${note}</td></tr>
                        </table>
                        <h4>النموزج و صور الموقع:</h4>
                        <div class="image-container">
                            <div class="file-list">
                                <h5>النموزج:</h5>
                                ${modelPhotos.map(photo => {
            const isPdf = photo.url.endsWith('.pdf');
            return isPdf
                ? `<a href="${photo.url}" target="_blank" style="display: block; margin: 5px;">${photo.url.split('/').pop()}</a>`
                : `<img src="http://rasm.runasp.net${photo.url}" style="width: 250px; margin: 5px;" />`;
        }).join('')}
                            </div>
                            <div class="file-list">
                                <h5>صور الموقع:</h5>
                                ${sitePhotos.map(photo => {
            const isPdf = photo.url.endsWith('.pdf');
            return isPdf
                ? `<a href="${photo.url}" target="_blank" style="display: block; margin: 5px;">${photo.url.split('/').pop()}</a>`
                : `<img src="http://rasm.runasp.net${photo.url}" style="width: 250px; margin: 5px;" />`;
        }).join('')}
                            </div>
                        </div>
                        <h4>مخالفات السلامة:</h4>
                        ${isExist ? (
                `<div class="file-list">
                                ${safetyWastePhotos.map(photo => {
                    const isPdf = photo.url.endsWith('.pdf');
                    return isPdf
                        ? `<a href="${photo.url}" target="_blank" style="display: block; margin: 5px;">${photo.url.split('/').pop()}</a>`
                        : `<img src="${photo.url}" style="width: 250px; margin: 5px;" />`;
                }).join('')}
                            </div>`
            ) : `<p>لا توجد مخالفات سلامة.</p>`}
                        <h4>ملاحظات إضافية:</h4>
                        <p>${note}</p>
                    </div>
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.print();
    };

    const handleExportExcel = () => {
        const ws = XLSX.utils.json_to_sheet([{
            رقم_الطلب: orderNumber,
            المقاول: contractor,
            الحي: district,
            المحطة: station,
            ملاحظات: note,
        }]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'الطلب');
        XLSX.writeFile(wb, 'order.xlsx');
    };

    const handleDelete = async () => {
        try {
            const response = await axios.delete(`${Url}Search/delete-by-orderidWithType?orderId=${id}&type=${type}`, {
                params: {
                    orderId: id,
                    type: type,
                },
            });

            if (response.status === 200) {
                setShowModal(false);
                showCustomAlert('تم حذف الطلب بنجاح');
                navigate('/search-requests');
            } else {
                showCustomAlert('حدث خطأ أثناء حذف الطلب');
            }
        } catch (error) {
            showCustomAlert('حدث خطأ أثناء حذف الطلب');
            console.error(error); 
        }
    };

    const showCustomAlert = (message) => {
        Swal.fire({
            text: message,
            icon: 'success',
            confirmButtonText: 'موافق'
        });
    };

    return (
        <div className="form-container" id="form-container" dir='rtl'>
            {/* <div className='radio-container'>
                <h2>نوع المشروع</h2>
                <div className="radio-group">
                    <label>
                        <input type="radio" value="network" checked={projectType} readOnly />
                        <span className="radio-circle" />
                        {projectType}
                    </label>
                </div>
            </div> */}
            <div className='FormData'>
                <div className='container'>
                    <h3>بيانات الطلب</h3>
                    <p>Quote is generated upon loading the form</p>
                    <div className="input-group">
                        <div className='groupInput'>
                            <label>رقم الطلب</label>
                            <input type="text" value={orderNumber} readOnly />
                        </div>
                        <div className='groupInput'>
                            <label>مقاول</label>
                            <input type="text" value={contractor} readOnly />
                        </div>
                    </div>
                    <div className="input-group">
                        <div className='groupInput'>
                            <label>الحي</label>
                            <input type="text" value={district} readOnly />
                        </div>
                        <div className='groupInput'>
                            <label>المحطه</label>
                            <input type="text" value={station} readOnly />
                        </div>
                    </div>
                    <div className="upload-section">
                        <h4>النموزج</h4>
                        <div className="file-list">
                            {modelPhotos.map((photo, index) => {
                                const isPdf = photo.url.endsWith('.pdf');
                                return isPdf
                                    ? <div key={index} className="file-item"><a href={photo.url} target="_blank" style={{ display: 'block', margin: '5px' }}>{photo.url.split('/').pop()}</a></div>
                                    : <div key={index} className="file-item"><img src={photo.url} alt={`Model ${index + 1}`} style={{ width: '50px', height: '50px' }} /></div>;
                            })}
                        </div>
                    </div>
                    <div className="upload-section">
                        <h4>صور الموقع</h4>
                        <div className="file-list">
                            {sitePhotos.map((photo, index) => {
                                const isPdf = photo.url.endsWith('.pdf');
                                return isPdf
                                    ? <div key={index} className="file-item"><a href={photo.url} target="_blank" style={{ display: 'block', margin: '5px' }}>{photo.url.split('/').pop()}</a></div>
                                    : <div key={index} className="file-item"><img src={photo.url} alt={`Site ${index + 1}`} style={{ width: '50px', height: '50px' }} /></div>;
                            })}
                        </div>
                    </div>
                    <div className="upload-section">
                        <h4>مخالفات السلامة</h4>
                        {safetyWastePhotos.length > 0 ? (
                            <div className="file-list">
                                {safetyWastePhotos.map((photo, index) => {
                                    const isPdf = photo.url.endsWith('.pdf');
                                    return isPdf
                                        ? <div key={index} className="file-item"><a key={index} href={photo.url} target="_blank" style={{ display: 'block', margin: '5px' }}>{photo.url.split('/').pop()}</a></div>
                                        : <div key={index} className="file-item"><img src={photo.url} alt={`Safety ${index + 1}`} style={{ width: '50px', height: '50px' }} /></div>;
                                })}
                            </div>
                        ) : (
                            <p>لا توجد مخالفات سلامة.</p>
                        )}
                    </div>
                    <div className="input-group">
                        <textarea value={note} readOnly />
                    </div>
                    <div className="button-group">
                        <button type="button" className="export-button" onClick={handlePrint} style={{ background: 'rgba(76, 175, 79, 1)', color: 'white' }}>
                            طباعة
                        </button>
                        <button type="button" className="export-button" onClick={handleExportExcel} style={{ background: 'rgba(76, 175, 79, 1)', color: 'white' }}>
                            تصدير إلى إكسل
                        </button>
                        <button type="button" className="export-button" onClick={() => setShowModal(true)} style={{ background: 'rgba(244, 67, 54, 1)', color: 'white' }}>
                            حذف الطلب
                        </button>
                    </div>
                </div>
            </div>
            {showModal && (
                <div className="modal-open">
                    <div className="modal-content">
                        <h2>تأكيد الحذف</h2>
                        <p>هل أنت متأكد أنك تريد حذف هذا الطلب؟</p>
                        <button className="close-modal-button" onClick={handleDelete}>نعم</button>
                        <button className="close-modal-button" onClick={() => setShowModal(false)}>لا</button>
                    </div>
                </div>

            )}
        </div>
    );
};

export default Form;
